

@media (min-width: 576px){
	.slider-top > .container > .row > div > img {
		height: 210px;
		margin: 0;
	}
}
@media (min-width: 768px){
	.slider-top > .container > .row > div > img {
		height: 165px;
		margin: 165px 0 0 0;
	}
	.slider-top > .container > .row {
		height: 330px;
	}
}
@media (min-width: 992px){
	.slider-top > .container > .row > div > img {
		height: 220px;
		margin: 110px 0 0 0;
	}
}
@media (min-width: 1200px){
	.slider-top > .container > .row > div > img {
		height: 270px;
		margin: 60px 0 0 0;
	}
	.slider-top {
		background-size: 104% auto;
	}
	.slider-home .slgo-insalute p, .slider-404 .slgo-insalute p {
		max-width: 400px;
	}
}
@media (min-width: 1400px){
	.slider-top > .container > .row > div > img {
		height: 300px;
		margin: 30px 0 0 0;
	}
}






@media (min-height: 740px){
	.slider-home, .slider-404 {
		height: calc(100vh - 40px - 1rem);
	}
	.slider-home > .container, .slider-404 > .container {
		display: grid !important;
		place-items: center !important;
		height: 100% !important;
	}
	.slider-home > small.slgo-go-down {
		position: absolute;
		display: inline-block;
		bottom: 30px;
		left: 0;
		width: 100%;
		padding-bottom: 0 !important;
	}
}






@media (max-width: 1400px) {
	.single-center-content.single-center-content-info .container .col-md-6 .slgo-img-single-center {
		min-height: 380px;
	}
}
@media (max-width: 1200px) {
	.single-center-content.single-center-content-info .container .col-md-6 .slgo-img-single-center {
		min-height: 340px;
	}
	.cta-cooperate h2 {
		font-size: calc(1.1rem + .9vw);
	}
}
@media (max-width: 991px) {
	.slgo-no-mobile {
		display: none !important;
	}
	.slgo-mobile {
		display: initial !important;
	}
	.slgo-mobile.btn {
		display: inline-block !important;
	}
    .single-center-content.single-center-content-info .container .col-md-6 {
		min-height: 240px;
	}
	.single-center-content.single-center-content-info .container .col-md-6 .slgo-img-single-center {
		min-height: 240px;
	}
	.related-centers.slgo-single-center-related-centers .container .row-grid .slgo-img-single-center-related-centers img {
		height: 100px;
	}
	.related-centers.slgo-single-center-related-centers .slgo-contact-related-center .slgo-btn-single-center {
		padding: 0 !important;
		width: 40px !important;
		height: 40px !important;
		line-height: 40px;
	}
	.slgo-ads-center {
		gap: 25px !important;
		grid-template-columns: 1fr !important;
	}
	.slgo-ads-center .slgo-single-ads-center .slgo-single-ads-center-img {
		grid-area: adsImg;
		padding-bottom: 10px;
	}
	.slgo-ads-center .slgo-single-ads-center .slgo-single-ads-center-title {
		grid-area: adsTitle;
		text-align: center;
		padding: 12px 0 20px 0 !important;
		margin: 0 !important;
	}
	.slgo-ads-center .slgo-single-ads-center .slgo-single-ads-center-icons {
		grid-area: adsIcons;
		justify-content: center;
		align-items: center;
		display: flex;
		gap: 3vw;
		flex-direction: column;
	}
	.slgo-ads-center .slgo-single-ads-center {
		display: grid;
		grid-template-areas:
			'adsTitle adsTitle adsTitle adsTitle'
			'adsImg adsImg adsImg adsIcons';
	}
	.slgo-ads-center .slgo-single-ads-center .slgo-single-ads-center-img {
		padding-bottom: 0;
	}
	.slgo-ads-center .slgo-single-ads-center .slgo-single-ads-center-img:after {
		top: unset;
		bottom: 297px;
	}
}
@media (max-width: 767px) {
    .slgo-btn-search {
        width: 100%;
    }
	.single-center-content.single-center-content-info .container .col-md-6 {
		min-height: 340px;
	}
	.single-center-content.single-center-content-info .container .col-md-6 + .col-md-6 {
		min-height: 0;
	}
	.single-center-content .spacer-single-center {
		border: 0 !important;
	}
	.container-h-100 {
		height: auto;
	}
	.single-service-content.single-service-content-all .container-top.container-top-2 h2.slgo-title {
		font-size: calc(1.2rem + .8vw);
	}
	.related-centers.slgo-single-center-related-centers .container .row-grid {
		grid-template-columns: 1fr;
		gap: 1rem;
	}
	.related-centers.slgo-single-center-related-centers .container .row-grid .slgo-img-single-center-related-centers {
		display: grid;
		/* grid-template-columns: 1fr 3fr 1.5fr; */
		grid-template-columns: 1fr 3fr 1.5fr;
		gap: 1rem;
		align-items: center;
	}
	.related-centers.slgo-single-center-related-centers .container .row-grid .slgo-img-single-center-related-centers img {
		height: auto;
	}
	.related-centers.slgo-single-center-related-centers .slgo-img-single-center-related-centers::before {
		left: 0;
		top: 0;
	}
	.related-centers.slgo-single-center-related-centers .container .row-grid .slgo-img-single-center-related-centers {
		padding-bottom: 0 !important;
	}
	.related-centers.slgo-single-center-related-centers .slgo-contact-related-center {
		justify-content: end;
		gap: 0.5rem;
		left: unset;
		right: 0.5rem;
		height: 100%;
		align-items: center;
	}
	.slgo-ads-center .slgo-single-ads-center .slgo-single-ads-center-img:after {
		bottom: 200px;
	}
}
@media (max-width: 575px) {
	.btn.btn-only-icon {
		padding: 12px !important;
		border-radius: 50% !important;
		width: 49px !important;
		height: 49px !important;
		line-height: 25px;
	}
	.single-center-content.single-center-content-info .container .col-md-6 {
		min-height: 58vw;
	}
    .slgo-mobile-w-94 {
        max-width: 94% !important;
    }
	.slgo-centers-pagination .page-item {
		display: none;
	}
	.slgo-centers-pagination .page-item:first-child,
	.slgo-centers-pagination .page-item:last-child,
	.slgo-centers-pagination .page-item:nth-child(2),
	.slgo-centers-pagination .page-item:nth-child(3),
	.slgo-centers-pagination .page-item.active,
	.slgo-centers-pagination .page-item.active + .page-item,
	.slgo-centers-pagination .page-item.active + .page-item + .page-item,
	.slgo-centers-pagination .page-item:nth-last-child(2) {
		display: block;
	}
	.single-service-content.single-service-content-all .container-top.container-top-2 h2.slgo-title {
		font-size: calc(1.1rem + .8vw);
	}
	.single-center-content.single-center-content-info .container .col-md-6 .slgo-img-single-center {
		box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 0px 1px;
	}
	.single-center-content.single-center-content-info .container .col-md-6 .slgo-img-single-center {
		min-height: 66vw;
	}
	.cta-cooperate h2 {
		font-size: calc(1.25rem + .9vw);
	}
	.slider-top > .container > .row > div > img {
		display: none;
	}
	.slider-home, .slider-404 {
		background-size: 100% auto;
		height: calc(100vh - 40px - 1rem);
	}
	.slgo-btn-search.slgo-btn-search-home {
		padding: 9px 20px;
	}
	.slider-home h4, .slider-404 h4 {
		font-size: calc(1.1rem + .2vw);
	}
	.slider-home img, .slider-404 img {
		width: 70%;
	}
	.slider-home > .container, .slider-404 > .container {
		display: grid !important;
		place-items: center !important;
		height: 100% !important;
	}
	.slider-home > .container > .row, .slider-404 > .container > .row {
		margin-top: -60px !important;
	}
	.slider-home > small.slgo-go-down {
		position: absolute;
		display: inline-block;
		bottom: 30px;
		left: 0;
		width: 100%;
		padding-bottom: 0 !important;
	}
	.slgo-centers-list .slgo-center .btn {
		width: 100%;
	}
	.related-centers.slgo-single-center-related-centers .container .row-grid .slgo-img-single-center-related-centers {
		grid-template-columns: 2fr 5fr 2.5fr;
		grid-template-columns: 3fr 4fr;
	}
	.related-centers.slgo-single-center-related-centers .slgo-img-single-center-related-centers::before{
		width: 9vw;
		height: 9vw;
	}
	.slgo-centers-list .slgo-center .float-end .btn-only-icon {
		float: right !important;
	}
	.slgo-centers-list .slgo-center .float-end .btn-only-icon:first-child {
		margin-right: 0 !important;
	}
	.single-service-content.single-service-content-info .slgo-title::before {
		margin-right: 22px;
		flex: 0 0 75px;
		height: 75px;
	}
	.related-centers.slgo-single-center-related-centers .slgo-contact-related-center {
		left: unset;
		display: flex;
		right: 0;
		bottom: 9px;
		height: 40px;
		width: 100%;
		justify-content: center;
		gap: 4vw;
	}
	.related-centers.slgo-single-center-related-centers .container .row-grid .slgo-img-single-center-related-centers {
		padding-bottom: 60px !important;
	}
	.related-centers.slgo-single-center-related-centers .slgo-img-single-center-related-centers .slgo-center-img-bg {
		border-radius: 0 0 10px 0;
	}
	.related-centers.slgo-single-center-related-centers .container .row-grid .slgo-img-single-center-related-centers {
		display: block;
	}
	.related-centers.slgo-single-center-related-centers .container .row-grid .slgo-div-single-center-related-centers {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
	.related-centers.slgo-single-center-related-centers .container .row-grid .slgo-contact-big-btn-related-center {
		padding-left: 5%;
		display: grid;
		align-items: center;
		justify-content: center;
		grid-auto-flow: column;
		gap: 5%;
	}
	.related-centers.slgo-single-center-related-centers .container .row-grid .slgo-contact-big-btn-related-center a {
		background-color: var(--slgo-green-bg) !important;
	}
	.related-centers.slgo-single-center-related-centers .slgo-contact-related-center {
		display: none !important;
	}
	.related-centers.slgo-single-center-related-centers .container .row-grid .slgo-img-single-center-related-centers {
		padding: 0 !important;
	}
	.related-centers.slgo-single-center-related-centers .container .row-grid .slgo-single-related-center h6 {
		clear: both;
		display: block;
		width: 100%;
		margin: 0 !important;
		padding: 15px 10px !important;
		text-align: center;
	}
	.slgo-ads-center .slgo-single-ads-center .slgo-single-ads-center-img:after {
		display: none;
	}
}
@media (max-width: 479px) {
    .navbar-top .navbar-brand.logo-desktop {
		display: none;
	}
	.navbar-top .navbar-brand.logo-mobile {
		display: block;
		width: 50px;
		text-align: left;
	}
	.navbar-top .navbar-brand.logo-mobile img {
		width: 90%;
		margin: 12px 0 -12px 0;
	}
	.navbar-top .navbar-brand::after {
		height: 80px;
		top: -15px;
	}
	.navbar-top .navbar-brand::before {
		content: "";
		display: block;
		background-image: url(../../../../public/img/logo/logo-white.svg);
		background-size: 100% auto;
		background-position: center;
		width: 150px;
		height: 50px;
		background-repeat: no-repeat;
		position: absolute;
		z-index: 3;
		left: 60px;
		top: 0px;
	}
	.single-service-content.single-service-content-all .container-top.container-top-2 h2.slgo-title {
		font-size: calc(1rem + .7vw);
	}
	.single-service-content .slgo-title::before {
		margin-right: 20px;
		flex: 0 0 90px;
		height: 90px;
	}
	.single-service-content.single-service-content-all .slgo-service-card {
		background-size: 98% auto;
	}
	.single-center-content .container-top .slgo-btn-single-center {
		width: 100%;
	}
	.related-centers.slgo-single-center-related-centers .container .row-grid {
		gap: 0.5rem;
	}
	.related-centers.slgo-single-center-related-centers .container .row-grid .slgo-img-single-center-related-centers {
		grid-template-columns: 1fr 2fr;
		/* align-items: start; */
		min-height: 115px;
	}
	.related-centers.slgo-single-center-related-centers .container .row-grid .slgo-img-single-center-related-centers h6 {
		/* padding-top: 0.75rem !important; */
	}
	.related-centers.slgo-single-center-related-centers .slgo-contact-related-center {
		justify-content: end;
		gap: 0.5rem;
		right: 0.5rem;
		height: auto;
		bottom: 0.5rem;
	}
	.related-centers.slgo-single-center-related-centers .container .row-grid .slgo-center-img {
		height: 100%;
	}
	.related-centers.slgo-single-center-related-centers .container .row-grid .slgo-center-img img {
		height: 100%;
	}
	.related-centers.slgo-single-center-related-centers .slgo-img-single-center-related-centers::before{
		width: 11vw;
		height: 11vw;
	}
	.single-service-content.single-service-content-info .slgo-title {
		display: block;
	}
	.single-service-content.single-service-content-info .slgo-title::before {
		display: none;
		margin: 0;
	}
	.slgo-ads-center .slgo-single-ads-center {
		display: grid;
		grid-template-areas:
			'adsTitle adsTitle adsTitle'
			'adsImg adsIcons adsIcons';
	}
	.slgo-ads-center .slgo-single-ads-center .slgo-single-ads-center-img {
		width: 60vw;
	}
	.slgo-ads-center .slgo-single-ads-center .slgo-single-ads-center-icons {
		padding-right: 3vw !important;
	}
}
@media (max-width: 420px) {
	.tab-content .form-check.form-check-inline {
		display: block;
		margin: 12px 0 0 0;
	}
	.slgo-reset-filters {
		text-align: center;
		display: block;
		width: 100%;
	}
	.single-service-content .slgo-title {
		display: block;
		flex: unset;
	}
	.single-service-content .slgo-title::before {
		display: block;
		width: 115px;
		height: 115px;
		margin: 0 auto 28px 0;
	}
	.cta-cooperate h2 {
		font-size: calc(1.2rem + .9vw);
	}
	.single-center-content .container-top, .single-service-content .container-top {
		padding-top: 4rem !important;
	}
	.slider-home img, .slider-404 img {
		width: 74%;
	}
	.related-centers.slgo-single-center-related-centers .container .row-grid .slgo-img-single-center-related-centers {
		grid-template-columns: 2fr 3fr;
	}
	.related-centers.slgo-single-center-related-centers .slgo-img-single-center-related-centers::before{
		width: 13vw;
		height: 13vw;
	}
	.slgo-ads-center .slgo-single-ads-center .slgo-single-ads-center-icons {
		padding-right: 2.2vw !important;
	}
}
@media (max-width: 360px) {
	.related-centers.slgo-single-center-related-centers .container .row-grid .slgo-div-single-center-related-centers {
		grid-template-columns: 3fr 4fr;
	}
	.slgo-ads-center .slgo-single-ads-center {
		grid-template-areas:
			'adsTitle adsTitle'
			'adsImg adsIcons';
	}
	.slgo-ads-center .slgo-single-ads-center .slgo-single-ads-center-img {
		width: 55vw;
	}
	.slgo-ads-center .slgo-single-ads-center .slgo-single-ads-center-icons {
		padding-right: 4vw !important;
	}
}
@media (max-width: 320px) {
	.slgo-ads-center .slgo-single-ads-center .slgo-single-ads-center-img {
		width: 50vw;
	}
	.slgo-ads-center .slgo-single-ads-center .slgo-single-ads-center-icons {
		padding-right: 3.5vw !important;
	}
}



